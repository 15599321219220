<template>
  <v-row justify="center">
    <v-dialog light v-model="servicesDialog" persistent max-width="900px">
      <v-card>
        <v-card-title class="headline px-2">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="text-h5 pl-0">
              {{ formData.id ? "Editar Serviço" : "Novo Serviço" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-fab-transition> -->
            <v-btn
              @click="toogleRegisterServiceDilog"
              class="mt-2"
              color="red"
              fab
              icon
              dark
              x-small
              absolute
              top
              right
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- </v-fab-transition> -->
          </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-tabs class="" v-model="tab" id="serviceFormTab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab>Dados básicos do serviço</v-tab>
            <v-tab>Credenciais de API</v-tab>
          </v-tabs>
          <v-tabs-items
            style="min-height: 310px; border-top: 1px solid #ccc"
            v-model="tab"
          >
            <v-tab-item>
              <v-container class="px-4">
                <v-form ref="serviceForm" v-model="valid">
                  <v-row>
                    <v-col cols="12" sm="6" md="8">
                      <v-text-field
                        v-model="formData.name"
                        label="Nome do serviço"
                        :rules="$store.state.formRules.required"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        :rules="$store.state.formRules.required"
                        v-model="formData.type"
                        :items="['App', 'Hospedagem']"
                        label="Tipo de serviço"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="formData.prefix"
                        label="Prefixo do APP"
                        :rules="$store.state.formRules.required"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field-money
                        v-model="formData.price"
                        v-bind:label="'Preço'"
                        v-bind:properties="{
                          rules: $store.state.formRules.required,
                          prefix:
                            formData.price && formData.price.length ? 'R$' : '',
                          readonly: false,
                          disabled: false,
                          outlined: false,
                          clearable: true,
                          placeholder: ' ',
                        }"
                        v-bind:options="{
                          locale: 'pt-BR',
                          length: 11,
                          precision: 2,
                          empty: null,
                        }"
                      />
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="formData.description"
                        label="Descrição"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col
                      class="py-0"
                      v-if="!formData.id"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-switch
                        class="labelTitle"
                        v-model="formData.db_create"
                        color="primary"
                        label="Criar um banco de dados"
                      ></v-switch>
                    </v-col> -->

                    <!-- <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="formData.database_name"
                        label="Nome do banco de dados"
                        :rules="$store.state.formRules.required"
                      ></v-text-field>
                    </v-col> -->
                    <!-- <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="formData.db_user"
                        label="Usuário do banco de dados"
                        :rules="$store.state.formRules.required"
                      ></v-text-field>
                    </v-col> -->
                    <!-- <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="formData.db_password"
                        label="Senha do banco de dados"
                        :rules="$store.state.formRules.required"
                      ></v-text-field>
                    </v-col> -->
                    <!-- <v-col cols="12" sm="8">
                      <v-text-field
                        v-model="formData.endpoint"
                        label="Endpoint"
                        :rules="$store.state.formRules.required"
                      ></v-text-field>
                    </v-col> -->

                    <!-- <v-select
                    :rules="$store.state.formRules.required"
                    v-model="selectedCompany"
                    :items="companies"
                    item-text="name"
                    item-value="id"
                    label="Empresas"
                    return-object
                    single-line
                  ></v-select> -->
                  </v-row>
                </v-form>
              </v-container>
            </v-tab-item>
            <v-tab-item
              ><v-container class="px-4">
                <v-form ref="apiCredencialForm" v-model="valid">
                  <v-row>
                    <v-col class="py-0 mt-4" cols="12" sm="12" md="12">
                      <v-switch
                        class="labelTitle"
                        v-model="registerApi"
                        color="primary"
                        label="Cadastrar dados de Api"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        :class="[!registerApi ? 'iptDisab' : '']"
                        :readonly="!registerApi"
                        v-model="formData.client_id"
                        label="Client ID"
                        :rules="
                          registerApi ? $store.state.formRules.required : []
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        :class="[!registerApi ? 'iptDisab' : '']"
                        :readonly="!registerApi"
                        v-model="formData.client_secret"
                        label="Client secret"
                        :rules="
                          registerApi && !formData.id
                            ? $store.state.formRules.required
                            : []
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col class="mb-10" cols="12" sm="6" md="6">
                      <v-text-field
                        :class="[!registerApi ? 'iptDisab' : '']"
                        :readonly="!registerApi"
                        v-model="formData.api_url"
                        label="Url da Api"
                        :rules="
                          registerApi ? $store.state.formRules.required : []
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions
          class="px-0 mx-3"
          style="border-top: 1px solid rgb(220, 220, 220)"
        >
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="toogleRegisterServiceDilog">
            Fechar
          </v-btn>
          <v-btn color="green" text @click="confirmToSaveService">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const resetServicesForm = {
  client_id: "",
  client_secret: "",
  api_url: "",
  id: "",
  name: "",
  db_create: false,
  description: "",
  price: "",
  database_name: "",
  db_user: "",
  db_password: "",
  db_created: "",
  endpoint: "",
  type: "",
  prefix: "",
};
export default {
  methods: {
    getCompanyies() {
      var obj = {
        url: "/api/company/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.companies = [...response.data];
      });
    },
    confirmToSaveService() {
      if (this.formData.id && this.formData.client_secret) {
        let obj = {
          message: `Você esta alterando o campo "Client secret", deseja continuar?`,
          title: "Alterando Client secret",
          callback: (_) => {
            this.saveService();
          },
          type: "warning",
          btConfirm: { name: "Sim", show: true },
          btCancel: { name: "Não", show: true },
        };
        this.$store.commit("confirmMessage/showConfirmMessage", obj);
      } else {
        this.saveService();
      }
    },
    saveService() {
      //console.log("servicsave");
      // if (!this.$refs.serviceForm.validate()) {
      //   return;
      // }
      let tabs = document.querySelectorAll("#serviceFormTab .v-tab");
      let serviceFrm = this.$refs.serviceForm.validate();
      let dataApiFrm = this.$refs.apiCredencialForm.validate();
      // console.log(userSbscrib, dataSubscrib);
      if (!serviceFrm && !dataApiFrm) {
        return;
      } else if (!serviceFrm) {
        tabs[0].click();
        return;
      } else if (!dataApiFrm) {
        tabs[1].click();
        return;
      }

      let url = this.formData.id
        ? "/api/service/update"
        : "/api/service/create";
      if (this.formData.id) {
        this.formData.company_id = this.selectedCompany.id;
      }
      var obj = {
        url: url,
        query: this.formData,
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        let opts = {
          message: `Serviço ${
            this.formData.id ? "atualizado" : "criado"
          } com sucesso`,
          type: "success",
        };
       // this.formData.client_secret = "";
        this.$store.commit("showAlert", opts);
        this.updateServices(response.data);
        this.toogleRegisterServiceDilog();
      });
    },
  },
  watch: {
    registerApi(v) {
      if (!v) {
        this.$refs.serviceForm.resetValidation();
        this.$refs.apiCredencialForm.resetValidation();
        this.formData.client_id = "";
        this.formData.client_secret = "";
        this.formData.api_url = "";
      }
    },
    servicesDialog(v) {
      if (!v) {
        this.$refs.serviceForm.resetValidation();
        this.$refs.apiCredencialForm.resetValidation();
        this.registerApi = false;
        this.selectedCompany = null;
        this.formData = { ...resetServicesForm };
      } else {
        setTimeout(() => {
          let tabs = document.querySelectorAll("#serviceFormTab .v-tab");
          tabs[1].click();
          setTimeout(() => {
            tabs[0].click();
          }, 10);
        }, 10);
        if (this.editServiceData) {
          let index = this.companies.findIndex(
            (company) => company.id === this.editServiceData.company_id
          );
          this.selectedCompany = { ...this.companies[index] };
          this.formData = this.editServiceData;
          this.editServiceData.client_id ||
          this.editServiceData.client_secret ||
          this.editServiceData.api_url
            ? (this.registerApi = true)
            : (this.registerApi = false);
        }
      }
    },
  },
  mounted() {
    this.getCompanyies();
  },
  props: {
    updateServices: Function,
    editServiceData: Object,
    toogleRegisterServiceDilog: Function,
    servicesDialog: Boolean,
  },
  data() {
    return {
      registerApi: false,
      tab: null,
      companies: [],
      selectedCompany: null,
      valid: true,
      formData: { ...resetServicesForm },
    };
  },
};
</script>

<style>
.iptDisab label {
  color: #cfcfcf !important;
}
.labelTitle label {
  font-size: 18px !important;
  font-weight: 500 !important;
}
</style>