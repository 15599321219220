<template>
  <div class="pl-16 pr-2">
    <v-card
      :min-height="windowH"
      class="mx-auto mt-3 mb-9 bgTransparente"
      max-width="calc(100% - 10px)"
      elevation="0"
    >
      <v-card-title class="ml-15 pt-8 pl-0 black--text"
        >Serviços cadastrados</v-card-title
      >
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="toogleRegisterServiceDilog"
            v-bind="attrs"
            v-on="on"
            class="mt-13 mr-10"
            color="primary"
            fab
            dark
            :fixed="responsiveSize < 500"
            :absolute="responsiveSize >= 500"
            :bottom="responsiveSize < 500"
            :top="responsiveSize >= 500"
            right
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Adicionar Serviço</span>
      </v-tooltip>
      <v-data-table
        light
        :headers="headers"
        :items="services"
        :items-per-page="10"
        class="elevation-0 opacityTable mx-15"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="btMicro px-1 mr-3"
                @click="editService(item)"
                x-small
                color="primary"
                dark
                ><v-icon center x-small> edit </v-icon></v-btn
              >
            </template>
            <span class="miniTtip">Editar serviço</span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="btMicro px-1"
                @click="confirmDelService(item)"
                x-small
                color="red"
                dark
                ><v-icon center x-small> mdi-delete-forever </v-icon></v-btn
              >
            </template>
            <span class="miniTtip">Excluir serviço</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <RegisterServices
      :updateServices="updateServices"
      :editServiceData="editServiceData"
      :servicesDialog="servicesDialog"
      :toogleRegisterServiceDilog="toogleRegisterServiceDilog"
    />
  </div>
</template>

<script>
import RegisterServices from "./RegisterServices.vue";
export default {
  computed: {
    responsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  beforeMount() {
    this.getHeightPage();
  },
  mounted() {
    this.getServices();
  },
  created() {
    window.addEventListener("resize", this.getHeightPage);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeightPage);
  },
  methods: {
    updateServices(data) {
      //console.log("update");
      let index = this.services.findIndex((service) => service.id === data.id);
      if (index > -1) {
        this.services.splice(index, 1);
        this.services.splice(index, 0, data);
      } else {
        this.services.push(data);
      }
    },
    editService(data) {
      this.editServiceData = { ...data };
      this.servicesDialog = true;
    },
    confirmDelService(data) {
      let obj = {
        message: `Você deseja excluir o serviço <b>${data.name}</b>?`,
        title: "Excluir Serviço",
        callback: (_) => {
          this.delService(data);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delService(data) {
      let obj = {
        url: "/api/service/delete",
        query: { id: data.id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        let index = this.services.findIndex(
          (provider) => provider.id === data.id
        );
        this.services.splice(index, 1);
        let opts = {
          message: `Serviço <b>${data.name}</b> removido com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    toogleRegisterServiceDilog() {
      this.editServiceData = null;
      this.servicesDialog = !this.servicesDialog;
    },
    getHeightPage() {
      this.windowH = window.innerHeight - 125;
    },
    getServices() {
      var obj = {
        url: "/api/service/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.services = [...response.data];
      });
    },
  },
  data() {
    return {
      windowH: "580",
      editServiceData: null,
      servicesDialog: false,
      headers: [
        { text: "Nome", value: "name", sortable: true },
        { text: "Prefixo", value: "prefix", sortable: false },
        {
          text: "Descrição",
          value: "description",
          sortable: false,
        },
        // { text: "Endpoint", value: "endpoint", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      services: [],
    };
  },
  components: {
    RegisterServices,
  },
};
</script>

<style>
</style>